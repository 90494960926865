<template>
    <div>
        <span @click="closeToken(token)"
            class="my-search-token"
            v-for="token in tokens">
				<button type="button" class="close"><div>&times;</div></button>
        {{token.label}}<span v-show="token.label && token.value">:</span> {{token.value}}

        </span>

    </div>
</template>

<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'search-filter-tokens',
    	props: {
    		searchData: Object,
    		searchFields: Object
    	},
    	data: function() {
    		return {
    			tokens: []
    		};
    	},
    	methods: {
    		closeToken: function(token) {
    			for (let i = 0; i < this.tokens.length; i++) {
    				if (this.tokens[i] === token) {
    					let k = this.tokens[i].id;
    					this.searchData[k] = '';
    					V.log("deleting token", k);

    					if (k === 'from') {
    						this.searchData.till = undefined;
    						this.searchData.nights_max = undefined;
    						this.searchData.nights_min = undefined;
    					} else if (k === 'till') {
    						this.searchData.from = undefined;
    						this.searchData.nights_max = undefined;
    						this.searchData.nights_min = undefined;
    					} else if (k === 'pets') {
    						this.searchData.petsCount = undefined;
    					} else if (k === 'favorites') {
    						this.searchData[k] = ''
    						this.$parent.favorites = false;
    					}
    					this.tokens.splice(i);

    					this.$nextTick(function() {
    						this.$emit('updated');
    					});
    					break;

    				}
    			}
    		},
    		update: function(nv) {
    			nv = nv || this.searchData;
    			this.tokens = [];
    			var field, value, v;
    			for (let k in nv) {
    				v = nv[k];

    				field = this.searchFields[k];

    				if (field && v && v !== '0' && v !== 'false' && k !== 'region') { //!_.isBlank(v)
    					value = null;
    					console.log('k:::' + k)
    					if (k === 'from' || k === 'till') {
    						console.log('111111');
    						value = DateEx.formatDate(v);
    					} else if (v !== true && v !== 'true' && k !== 'ids' && k != 'floor_id' && k != 'parkingCount_min') {
    						console.log('2222222222');
    						//show value
    						if (field.options) {
    							value = field.options[v + ''];
    						} else {
    							value = v;
    						}
    					} else if (k == 'floor_id') {
    						console.log('33333333333');
    						value = '';
    					} else if (k == 'parkingCount_min') {
    						console.log('44444444444');
    						value = '';
    					}

    					this.tokens.push({
    						id: k,
    						label: field.label,
    						value: value
    					});
    				} else if (field && v && v !== '0' && v !== 'false' && k === 'region') {
    					//console.log('Field::', field, 'value::', v);
    					value = field.options[v];
    					this.tokens.push({
    						id: k,
    						value: value
    					});
    				}
    			}
    		}
    	}
    };
</script>

<style scoped>
</style>