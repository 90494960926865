import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units1: [],
			count1: null,
		};
	},

	mounted: function () {
		this.observeVisibility();
	},

	beforeMount: function () {
		var results1 = this.$el.getElementsByClassName('unit-list-section1');
		if (results1.length) {
			this.resultFields = findResultFields(results1[0].innerHTML, this.$el);
		}

	},
	updated: function () {
		this.applySlider1();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {



		observeVisibility() {
				const options = {
					root: null,
					rootMargin: '0px 0px 0px 0px',
					threshold: 0 // Einstellen Sie den Schwellenwert basierend auf Ihren Anforderungen
				};

				const callback = (entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							this.search4similar1();
							observer.unobserve(entry.target);
						}
					});
				};

				const observer = new IntersectionObserver(callback, options);
				observer.observe(document.getElementById('similarunits'));
			},

			search4similar1: function () {
				//console.log('Ich lege jetzt los!!!');

				var beds = 2;
				let el = document.querySelector("[data-beds]");
				if (el) {
					beds = parseInt(el.getAttribute('data-beds'), 10);
				} else {
					//console.log('element beds is not found..................');
				}
				var unitId;
				let e2 = document.querySelector("[data-unit-id]");
				if (e2) {
					unitId = parseInt(e2.getAttribute('data-unit-id'));
					//console.log('Unit id int:' + unitId);
				}
				var req;

				if (beds) {
					req = {
						fields: this.resultFields,
						filter: {
							'beds': beds
						},
						max: 6,
						sorting: 'random'
					};

					doReq('searchUnits', req)
						.then((res) => {
							var foundUnits = [];
							if (res.units.length) {
								for (let i = 0; i < res.units.length; i++) {
									if (res.units[i]._id !== unitId) {
										foundUnits.push(res.units[i]);
									}

								}
							}
							this.count1 = foundUnits.length;
							this.units1 = foundUnits;


						});
				}
			},


			getUnitSearchTarget: function (id) {
				if (window.screen.width < 768) {
					return '_self';
				} else {
					return 'unit_' + id;
				}
			},

			applySlider1: function () {
				$(".owl-carousel.similar1").owlCarousel({
					loop: false,
					margin: VOFFICE.settings.olwCarouselMargin,
					smartSpeed: 1000,
					autoplay: false,
					nav: VOFFICE.settings.olwCarouselNav,
					responsive: {
						0: {
							items: 1
						},
						768: {
							items: 2
						},
						993: {
							items: 3
						},
						1200: {
							items: 4

						}
					}
				});
			},

	}

};